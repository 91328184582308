<template>
  <main class="container">
    <div class="container-custom saved-listings__container">
      <div class="mb-3">
        <h1>{{ $t("Saved listings") }}</h1>
      </div>

      <Spinner v-if="getLoadings.listings" />
      <p class="text-danger" v-else-if="getErrors.listings.length">
        {{ getErrors.listings }}
      </p>
      <template v-else-if="listings.data.length">
        <div class="row">
          <div
            class="col-lg-4 col-sm-6 col-12"
            v-for="listing in listings.data"
            :key="listing.id"
          >
            <Listing
              :listingLink="
                listing.savedListingStatus === 'invitationCancelled' ||
                listing.savedListingStatus === 'notAssigned'
                  ? ''
                  : `/listings/${listing.listing.slug}`
              "
              :externalClass="['saved-listings__listing']"
              :style="[
                {
                  opacity:
                    listing.savedListingStatus === 'invitationCancelled' ||
                    listing.savedListingStatus === 'notAssigned'
                      ? 0.5
                      : 1,
                },
              ]"
              listingtextcontentExternalClass="pt-3"
              :listing="listing.listing"
            >
              <template v-slot:status>
                <!-- Pending -->
                <div
                  v-if="listing.savedListingStatus === 'pendingViewingRequest'"
                  class="listing__caption text-white pl-3"
                  :style="{ 'background-color': '#467599' }"
                >
                  <HourglassHalf color="#ffff" />
                  <span class="pl-3 listing__caption__text">{{
                    $t("savedListingsKey.status.pending")
                  }}</span>
                </div>

                <!-- Invitation received -->
                <div
                  v-else-if="listing.savedListingStatus === 'invitationRecieved'"
                  class="listing__caption text-white pl-3"
                  :style="{ 'background-color': '#54B16B' }"
                >
                  <Tick fill="#ffff" :width="16" :height="16" />
                  <span class="pl-2 listing__caption__text">
                    {{ $t("savedListingsKey.status.invited") }}
                    {{ listing.appointmentTime }}
                  </span>
                </div>
                <!-- No invitation -->
                <div
                  v-else-if="listing.savedListingStatus === 'invitationCancelled'"
                  class="listing__caption text-white pl-3"
                  :style="{ 'background-color': '#FF6464' }"
                >
                  <Times fill="#ffff" />
                  <span class="pl-2 listing__caption__text">
                    {{ $t("savedListingsKey.status.notInvited") }}
                  </span>
                </div>
                <!-- Waiting -->
                <div
                  v-else-if="listing.savedListingStatus === 'waitingForAssigning'"
                  class="listing__caption text-white pl-3"
                  :style="{ 'background-color': '#467599' }"
                >
                  <HourglassHalf color="#ffff" />
                  <span class="pl-2 listing__caption__text">
                    {{ $t("savedListingsKey.status.waiting") }}
                  </span>
                </div>
                <!-- Not assigned -->
                <div
                  v-else-if="listing.savedListingStatus === 'notAssigned'"
                  class="listing__caption text-white pl-3"
                  :style="{ 'background-color': '#FF6464' }"
                >
                  <Times fill="#ffff" />
                  <span class="pl-2 listing__caption__text">
                    {{ $t("savedListingsKey.status.notAssigned") }}
                  </span>
                </div>

                <!-- Assigned -->
                <div
                  v-else-if="listing.savedListingStatus === 'assigningRequested'"
                  class="listing__caption text-white pl-3"
                  :style="{ 'background-color': '#54B16B' }"
                >
                  <Tick fill="#ffff" :width="16" :height="16" />
                  <span class="pl-2 listing__caption__text">
                    {{ $t("savedListingsKey.status.accept") }}
                  </span>
                </div>
              </template>

              <template v-slot:controls>
                <router-link
                  :to="{
                    name: 'ListingRentLog',
                    params: { listing_id: listing.listing.id },
                  }"
                  class="btn btn-white mt-4 btn-block"
                  v-if="listing.listing.share_rent_log"
                >
                  {{ $t("View Rent Log") }}
                </router-link>
                <BButton
                  variant="white"
                  block
                  class="mt-4"
                  v-b-modal.confirm-cancel
                  @click="activeListing = listing"
                  v-else-if="
                    listing.savedListingStatus === 'pendingViewingRequest' ||
                    listing.savedListingStatus === 'invitationRecieved' ||
                    listing.savedListingStatus === 'waitingForAssigning'
                  "
                >
                  {{ $t("Cancel Request") }}
                </BButton>
              </template>
            </Listing>
          </div>
        </div>
      </template>
      <p v-else class="saved-listings__empty">
        {{
          $t(
            "Currently there are no saved listings. When you interact with listings, it will show here"
          )
        }}
      </p>
    </div>
    <b-modal id="confirm-cancel" ref="confirm-cancel" hide-header hide-footer>
      <div class="p-3" v-if="activeListing">
        <h1>
          {{ $t("Confirm cancelation") }}
        </h1>
        <p>
          {{
            $t(
              "You are about to cancel the viewing request, press the button below to confirm this action."
            )
          }}
        </p>
        <div class="d-flex justify-content-between">
          <button class="btn btn-white btn-sm" @click="$bvModal.hide('confirm-cancel')">
            {{ $t("Cancel") }}
          </button>
          <button
            class="btn btn-primary btn-sm"
            @click="
              changeStatus(
                `/user/listings/${activeListing.listing.id}/cancel-request`,
                activeListing.id,
                'invitationCancelled'
              )
            "
            :disabled="getLoadings.listingStatus"
          >
            <b-spinner
              v-if="
                getLoadings.listingStatus &&
                selectedIdx === activeListing.id &&
                currentStatus === 'invitationCancelled'
              "
              small
              label="Spinning"
              variant="white"
            ></b-spinner>
            {{ $t("Cancel Request") }}
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="confirm-accept" ref="confirm-accept" hide-header hide-footer>
      <div class="p-3" v-if="activeListing">
        <h1>
          {{ $t("Accept invitation") }}
        </h1>
        <p>
          {{
            $t(
              "You are about to accept the invitation for the viewing. A confirmation message is send to the broker. Please press the accept button below to confirm. "
            )
          }}
        </p>
        <div class="d-flex justify-content-between">
          <button
            class="btn btn-primary btn-sm btn-block"
            @click="
              changeStatus(
                `/user/listings/${activeListing.listing.id}/accept-request`,
                activeListing.id,
                'invitation_accepted'
              )
            "
            :disabled="getLoadings.listingStatus"
          >
            <b-spinner
              v-if="getLoadings.listingStatus && selectedIdx === activeListing.id"
              small
              label="Spinning"
              variant="white"
            ></b-spinner>
            {{ $t("Accept invitation") }}
          </button>
        </div>
      </div>
    </b-modal>
  </main>
</template>

<script>
import Listing from "../components/general/listing/Listing.vue";
// import ButtonWithIcon from "../components/ui/ButtonWithIcon.vue";
import HourglassHalf from "@/components/svg/HourglassHalf.vue";
import Times from "@/components/svg/Times.vue";
import Tick from "../components/svg/Tick.vue";
import { mapGetters, mapActions } from "vuex";
import Spinner from "../components/ui/Spinner.vue";
export default {
  components: {
    Listing,
    // ButtonWithIcon,
    HourglassHalf,
    Times,
    Tick,
    Spinner,
  },
  data() {
    return {
      selectedIdx: null,
      currentStatus: null,
      activeListing: null,
    };
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("listing", ["listings"]),
  },
  methods: {
    ...mapActions("listing", ["setListings", "changeSavedListingStatus"]),

    async changeStatus(url, listingIdx, status) {
      console.log("url, listingIdx, status", url, listingIdx, status);
      this.currentStatus = status;
      this.selectedIdx = listingIdx;
      await this.changeSavedListingStatus({ url, listingIdx, status });
      this.setListings({ url: "/user/listings/saved" });
      this.$bvModal.hide("confirm-cancel");
      this.$bvModal.hide("confirm-accept");
    },
  },
  async created() {
    await this.setListings({ url: "/user/listings/saved" });

    if (this.$route.query["cancel-appointment"]) {
      let listingId = this.$route.query["cancel-appointment"];
      this.activeListing = this.listings.data.find((x) => x.listing.id == listingId);
      this.$bvModal.show("confirm-cancel");
    }

    if (this.$route.query["accept-appointment"]) {
      let listingId = this.$route.query["accept-appointment"];
      this.activeListing = this.listings.data.find((x) => x.listing.id == listingId);
      this.$bvModal.show("confirm-accept");
    }
  },
};
</script>

<style lang="scss" scoped>
.saved-listings {
  // &__container {
  //   padding: 0px 100px;
  //   @include lg {
  //     padding: 0px 50px;
  //   }
  // }
  &__controlsContainer {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 40px;

    padding-bottom: 20px !important;

    @include lg {
      padding: 15px;
      padding-top: 30px;
    }
  }

  &__invitation-date {
    font-size: 0.875rem;
  }

  &__empty {
    @include font(1.325rem, #999999);

    @include xs {
      font-size: 1.25rem;
    }
  }
}

.cancel-btn {
  @include font(1rem, #8784aa, 500);
}

.listing {
  &__caption {
    padding: 10px 0;

    &__text {
      @include font(0.75rem, $white, 700);
    }
  }
}
</style>
