<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="`0 0 27 27`"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.097 4.88831L23.5555 3.6476C22.7956 3.03688 22.354 3.04395 21.7034 3.84753L10.499 17.6747L5.28481 13.3425C4.56545 12.7382 4.11545 12.7704 3.52402 13.5097L2.33409 15.059C1.73045 15.8188 1.80759 16.2502 2.52116 16.8461L9.95259 22.9931C10.7176 23.636 11.1483 23.5697 11.7397 22.8504L25.2905 6.73974C25.927 5.97474 25.8884 5.51767 25.097 4.88831Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
    export default{
        props:{
            fill:{
                type:String,
                default:'white'
            },
            width:{
                type:Number,
                default:27
            },
            height:{
                type:Number,
                default:27
            }
        }
    }
</script>
