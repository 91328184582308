<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.15879 0L7.5 4.34118L11.8412 0L15 3.15882L10.6588 7.5L15 11.8412L11.8412 15L7.5 10.6588L3.15879 15L0 11.8412L4.34121 7.49997L0 3.15879L3.15879 0Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default{
  props:{
    fill:{
      type:String,
      default:'#FE5F55'
    }
  }
}

</script>