<template>
  <svg
    width="15"
    height="19"
    viewBox="0 0 15 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_272_226)">
      <path
        d="M13.9879 0H1.34815C0.849522 0 0.445312 0.39874 0.445312 0.890625V1.48438C0.445312 1.97626 0.849522 2.375 1.34815 2.375C1.34815 5.75065 3.26729 8.5995 5.89403 9.5C3.26729 10.4005 1.34815 13.2493 1.34815 16.625C0.849522 16.625 0.445312 17.0237 0.445312 17.5156V18.1094C0.445312 18.6013 0.849522 19 1.34815 19H13.9879C14.4866 19 14.8908 18.6013 14.8908 18.1094V17.5156C14.8908 17.0237 14.4866 16.625 13.9879 16.625C13.9879 13.2493 12.0688 10.4005 9.44205 9.5C12.0688 8.5995 13.9879 5.75065 13.9879 2.375C14.4866 2.375 14.8908 1.97626 14.8908 1.48438V0.890625C14.8908 0.39874 14.4866 0 13.9879 0ZM11.1636 14.25H4.17254C4.81427 12.5134 6.13231 11.2812 7.66804 11.2812C9.20366 11.2812 10.5218 12.5131 11.1636 14.25ZM11.1643 4.75H4.17247C3.90575 4.02815 3.75573 3.21916 3.75573 2.375H11.5804C11.5804 3.22128 11.4304 4.0296 11.1643 4.75Z"
        :fill="color || '#504C4C'"
      />
    </g>
    <defs>
      <clipPath id="clip0_272_226">
        <rect
          width="14.4455"
          height="19"
          fill="white"
          transform="translate(0.445312)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>

<style lang="scss" scoped></style>
